import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

import './application/error.js'
import './application/layout.js'
import './application/save.js'
import './application/contenteditable.js'
import './application/form.js'
import './application/paste.js'
import './application/login.js'
