function handleErrors(event) {
  var data = event.detail && event.detail[0];
  var errors = data && data.errors;

  if (errors && event.target) {
    if (event.target.matches('form')) {
      updateFormErrors(event.target, errors);
    }
    else if (event.target.matches('.button')) {
      updateButtonErrors(event.target, errors);
    }
  }
}

function updateFormErrors(form, errors) {
  var error_spans = form.querySelectorAll('span.error');

  for (var i = 0; i < error_spans.length; i++) {
    error_spans[i].remove();
  }

  var fields = form.querySelectorAll('.field');

  for (var i = 0; i < fields.length; i++) {
    fields[i].classList.remove('error');
  }

  for (var name in errors) {
    var input = form.querySelector('[name=' + name + ']') ||
      form.querySelector('[name=' + name + '_id]');

    if (input) {
      var field = input.closest('.field');

      field.classList.add('error');

      var label = field.querySelector('label > span');

      if (label) {
        label.innerHTML += ' <span class="error">' + errors[name][0] +
          '.</span>';
      }
    }
  }
}

function updateButtonErrors(button, errors) {
  button.classList.add('disabled');
  setTimeout(function() {
    button.innerHTML = errors['base'] ? errors['base'][0] : 'Error';
  }, 1);
}

document.addEventListener('ajax:success', handleErrors);
