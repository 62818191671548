import Cookies from 'js-cookie';

function userCookie() {
  return Cookies('user') ? JSON.parse(Cookies('user')) : null;
}

function loggedIn() {
  return !!userCookie();
}

function name() {
  try {
    return userCookie().name;
  }
  catch(e) {
    return null;
  }
}

function returnTo() {
  var element = document.querySelector('#return-to');
  return element && (element.href || element.value);
}

function checkForLogin() {
  if (loggedIn()) {
    window.location = returnTo() || '/';
  }
  else {
    setTimeout(checkForLogin, 1000);
  }
}

function checkForName() {
  if (name()) {
    window.location = returnTo() || '/';
  }
  else {
    setTimeout(checkForName, 1000);
  }
}

if (!loggedIn()) {
  checkForLogin();
}
else if (!name()) {
  checkForName();
}
