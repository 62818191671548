if (window.navigator && window.navigator.standalone) {
  var savedPage = localStorage.getItem('saved-page');

  if (savedPage && savedPage.match(/^http/) &&
      window.history && window.history.length == 1) {

    document.addEventListener('DOMContentLoaded', function() {
      document.body.innerHTML = '';
      Turbolinks.visit(savedPage);
    });
  }
}

if (window.navigator && window.navigator.standalone) {
  document.addEventListener('turbolinks:load', function() {
    localStorage.setItem('saved-page', window.location.href);
  });
}
