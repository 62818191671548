function closeMenu() {
  var close = false;

  for (var i = 0; i < document.body.classList.length; i++) {
    var c = document.body.classList.item(i);

    if (c.match(/-open$/)) {
      document.body.classList.remove(c);
      close = true
    }
  }

  if (close) {
    blurCli();

    return false;
  }
}

function focusCli() {
  var form = document.querySelector('nav.cli form');

  if (form && form.input) {
    form.input.focus();
  }
}

function blurCli() {
  var form = document.querySelector('nav.cli form');

  if (form && form.input) {
    form.input.blur();
  }
}

function openCli() {
  var cli = document.querySelector('nav.cli');

  if (cli && document.body.classList.value.indexOf('-open') === -1) {
    document.body.classList.add('cli-open');
    focusCli();

    return false;
  }
}

function toggleCli() {
  var cli = document.querySelector('nav.cli');

  if (cli) {
    if (document.body.classList.contains('cli-open')) {
      document.body.classList.remove('cli-open');
      blurCli();
    }
    else {
      document.body.classList.add('cli-open');
      focusCli();
    }

    return false;
  }
}

function findParent(element, selector) {
  if (element && element.matches && element.matches(selector)) {
    return element;
  }
  else if (element.parentNode) {
    return findParent(element.parentNode, selector);
  }

  return null;
}

document.addEventListener('click', function(event) {
  var button = findParent(event.target, '.button');

  if (button && button.dataset.open) {
    document.body.classList.add(button.dataset.open + '-open');
  }
  if (button && button.dataset.close) {
    document.body.classList.remove(button.dataset.close + '-open');
  }

  if (button && (button.dataset.open || button.dataset.close)) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }
});

function handleClickOnMain(event) {
  var main = findParent(event.target, 'main');

  if (main) {
    return closeMenu();
  }
}

document.addEventListener('touchend', handleClickOnMain);
document.addEventListener('click', handleClickOnMain);

document.addEventListener('keydown', function(event) {
  if (event.ctrlKey && event.keyCode === 65) {
    return toggleCli();
  }
});

var swipe = { start: { x: null, y: null } };

document.addEventListener('touchstart', function(event) {
  var t = event.changedTouches && event.changedTouches[0];

  swipe.start.x = t.screenX;
  swipe.start.y = t.screenY;
});

document.addEventListener('touchend', function(event) {
  var t = event.changedTouches && event.changedTouches[0];

  var x = t.screenX;
  var y = t.screenY;
  var start = swipe.start;
  var r;

  if (start.x < 20 && x > 140 && Math.abs(start.y - y) < 40) {
    history.back();
  }
  else if (start.y < 60 && y > 140 && Math.abs(start.x - x) < 40) {
    r = openCli();
  }

  swipe.start.x = null;
  swipe.start.y = null;

  return r;
});

document.addEventListener('turbolinks:before-visit', function() {
  closeMenu();
});
