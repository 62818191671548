window.onerror = function(msg, source, lineno, colno, error) {
  var main = document.querySelector('main')
  if (main) {
    main.style.borderColor = 'red';
  }
  if (window.navigator && window.navigator.standalone) {
    alert('error: ' + msg + ', ' + source + ':' + lineno + ':' + colno);
  }
  else {
    console.log('error: ', msg, source, lineno, colno, error);
  }
};
