import { post } from './lib/api/post';

function contenteditable(element) {
  if (element === document) {
    return false;
  }
  else if (element.getAttribute('contenteditable') == 'true') {
    return true;
  }
  else if (element.parentNode) {
    return contenteditable(element.parentNode);
  }

  return false;
}


function onPaste(event) {
  var target = event.target;

  if (!contenteditable(target)) {
    return;
  }

  if (event.clipboardData && event.clipboardData.items) {
    for (var i = 0; i < event.clipboardData.items.length; i++) {
      var item = event.clipboardData.items[i];

      if (item && item.kind == 'file') {
        var file = item.getAsFile();
        var data = new FormData();
        data.append('blob', file);
        data.append('json', 1);

        post('/belongings', data, function(response) {
          document.execCommand('insertHTML', false, response.id);
        });
      }
    }
  }
  return false;
}

document.addEventListener('paste', onPaste);
