/* This defines a simple XmlHttpRequest based POST for hitting our api. The
 * expectation is that we'll always POST to our api's and always receive json
 * back. Is this a good assumption?
 *
 * Note: responseType = 'json' does not work in IE  : \
 */

import Rails from 'rails-ujs';

export function post(url, data, fn) {
  var xhr = new XMLHttpRequest();

  xhr.open('POST', url, true);
  xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());

  if (!(data instanceof FormData)) {
    data = JSON.stringify({ data: data });
    xhr.setRequestHeader('Content-Type', 'application/json');
  }

  xhr.responseType = 'json';

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      fn(xhr.response);
    }
  };

  xhr.send(data);
};
