function updateContenteditable(event) {
  var form = event.target;
  var fields = form.querySelectorAll('div[data-field]');

  for (var i = 0; i < fields.length; i++) {
    form[fields[i].getAttribute('data-field')].value = fields[i].innerHTML;
  }
}

document.addEventListener('submit', updateContenteditable);
document.addEventListener('ajax:before', updateContenteditable);
